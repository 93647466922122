import { graphql, useStaticQuery } from "gatsby";

// HOOK - From filesystem | Metadata | Getting site metadata
export const useSiteMetadata = () => {
  const data: any = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          instagramUsername
          twitterUsername
          siteUrl
          image
        }
      }
    }
  `)

  return data.site.siteMetadata;

}