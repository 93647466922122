import { graphql, Link, StaticQueryDocument, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { getLinkByChosenName, isRunningOnWeb } from '../../../functions/utils';

// PROJECT DETAIL COMPONENT - Intro component of project detail
const ProjectDetailIntro = (props: {
  projectName: string | undefined     // ->  current project name
  projectYear: any | undefined,
  projectDescription: any | undefined,
  projectIntroImage: any,             // ->  current page project intro image data path
  currentTheme: 'light' | 'dark',     // ->  current theme
  userLocale: string | undefined
}) => {

  // --- Getting images from site files and strapi 
  const iconsImagesQuery: any | undefined = data && useStaticQuery(data);
  const monitor: any = iconsImagesQuery.allFile.edges.filter((el: any) => (el.node.relativePath as string).includes('monitor') || null)[0];
  const computer: any = iconsImagesQuery.allFile.edges.filter((el: any) => (el.node.relativePath as string).includes('computer-phone') || null)[0];
  const _monitor: IGatsbyImageData = monitor?.node && getImage(monitor.node);
  const _computer: IGatsbyImageData = computer?.node && getImage(computer.node);
  const introImage: IGatsbyImageData = props?.projectIntroImage && getImage(props.projectIntroImage.localFile);
  // ---

  const hrefProject: string | undefined = props?.projectName && getLinkByChosenName(props.projectName.toLowerCase());

  return (
    <div className={`flex relative reverse-bg-zone-trigger flex-col min-h-screen px-6 py-6 z-10 lg:h-screen lg:flex-row lg:px-24 xl:px-36 ${props?.currentTheme && props.currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}  id="reverse-bg-zone-trigger">
      <div className='absolute items-center hidden pl-8 text-base font-medium lg:flex lg:h-3/4 xl:h-5/6'>
        <Link to="/projects" className={`px-6 py-8 border-2 rounded-full duration-300 aspect-square flex items-center justify-center ${props?.currentTheme && props.currentTheme === 'light' ? 'cardinal-button' : 'cardinal-button-dark'}`}>
          { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'Indietro' : 'Back'}
        </Link>
      </div>
      <div className='flex flex-col w-full h-[97vh]'>
        <div className='flex items-center justify-center w-full h-full lg:pt-6 3xl:pt-0'>
          <div className='relative flex items-center justify-center w-[70%] lg:w-1/4 3xl:w-1/3 duration-500 hover:w-[28%] 3xl:hover:w-[35%] aspect-square'>
            <GatsbyImage image={introImage && introImage} alt={props?.projectIntroImage && props.projectIntroImage?.caption} className='w-full rounded-full aspect-square' objectPosition='0px 0.5px' />
            <a id='visitProject' href={hrefProject} target='_blank' className='absolute flex duration-300 opacity-0 hover:opacity-100 cursor-pointer items-center justify-center w-full bg-black/[0.65] rounded-full aspect-square'>
              <span className='py-4 text-2xl font-medium text-white border border-white rounded-full px-14'>
                { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'Visita il sito' : 'Visit Site'}
              </span>
            </a>
          </div>
        </div>
        <div className='flex flex-col justify-end pb-10 lg:pb-6 xl:pb-0'>
          <span className='text-xs lg:text-2xl xl:text-3xl'>Domain Marketplace</span>
          <span className='pt-4 text-5xl font-medium lg:text-8xl xl:text-9xl lg:uppercase lg:pt-0'>
            {props?.projectName && props.projectName}
          </span>
        </div>
      </div>
      <div className='flex flex-row w-full text-left lg:absolute lg:right-24 xl:right-36 lg:pt-32 3xl:pt-40 lg:flex-col lg:w-48 xl:w-56 min-w-min lg:space-y-4 xl:space-y-10'>
        <div className='flex flex-col w-full'>
          <span className='project-description-title'>
            { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'Anno' : 'Year'}
          </span>
          <span className='project-description-subtitle'>
            {props?.projectYear && props.projectYear}
          </span>
        </div>
        <div className='flex flex-col w-full'>
          <span className='project-description-title'>
            { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'DESCRIZIONE' : 'DESCRIPTION'}
          </span>
          <span className='project-description-subtitle'>
            {props?.projectDescription && props.projectDescription}
          </span>
        </div>
        <div className='flex-col hidden w-full lg:flex'>
          <span className='project-description-title'>
            { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'SERVIZI' : 'SERVICES'}
          </span>
          <span className='project-description-subtitle'>
            UX Research & Strategy<br/>
            UI Design<br/>
            Support Platform<br/>
            Main Platform<br/>
            Technical Consulting
          </span>
        </div>
        <div className='items-center hidden lg:flex xl:space-x-8 3xl:space-x-16'>
          <GatsbyImage image={_monitor} alt={'monitor'} />
          <GatsbyImage image={_computer} alt={'computer'} />
        </div>
      </div>
    </div>
  )

}

export default ProjectDetailIntro;

// --- Graphql query getting icon image datas from site 
export const data: StaticQueryDocument | undefined = graphql`
  query {
    allFile(filter: {internal: {mediaType: {regex: "/image/"}}, extension: {ne: "svg"}, relativeDirectory: {eq: "img"}}) {
      edges {
        node {
          id
          name
          relativePath
          extension
          childImageSharp {
            id
            gatsbyImageData(layout: CONSTRAINED,  placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
// ---