import { useStaticQuery, graphql } from "gatsby"

export const useSiteProjectDetailI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      allStrapiProject {
        edges {
          node {
            localizations {
              data {
                attributes {
                  title
                  description
                  link
                  year
                  projectFunctionality_1
                  projectFunctionality_2
                  projectDescription_1
                  projectDescription_2
                  projectSmallDesc_1
                  projectSmallDesc_2
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiProject.edges;

}