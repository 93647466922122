import { graphql, StaticQueryDocument, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SEO } from '../../components/seo';
import { getUserPreferredLocale, isRunningOnWeb } from '../../functions/utils';
import { getTheme } from '../../functions/theme';
import BannerParallax from '../../components/banner-parallax';
import ProjectDetailIntro from './project-detail-components/project-detail-intro';
import ProjectDetailChange from './project-detail-components/project-detail-change';
import { useProjectDetailImages } from '../../hooks/project-detail-images';
import ProjectDetailLogos from './project-detail-components/project-detail-logos';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DefaultAppView from '../../app-view/default-app-view';
import { SEOData } from '../../constants/types';
import { useSiteProjectDetailI18N_IT } from '../../hooks/site-strapi-i18n-IT/site-project-detail-i18n-IT';
import { useSiteProjectDetailI18N_EN } from '../../hooks/site-strapi-i18n-EN/site-project-detail-i18n-EN';

gsap.registerPlugin(ScrollTrigger);

// PROJECT DETAIL LAYOUT - Each project detail page is created dynamically
const ProjectsDetail = () => {

  const _userLocale: string = getUserPreferredLocale();

  const _currentPath: string | undefined = isRunningOnWeb() ? window.location.pathname.split('/').slice(1, 3).pop() : undefined;
  const [currentPath, setCurrentPath] = useState<string | undefined>(_currentPath);

  useEffect(() => {
    setCurrentPath(_currentPath);
  }, [])

  // --- Getting project detail images
  const project: any = useProjectDetailImages();
  const _project: any = project && project?.edges.filter((el: any) => currentPath &&(el.node.title.toLowerCase() as string).includes(currentPath))[0];
  const project_images: any = _project && _project?.node.detailDecorationImgs;
  const images: any = project_images && project_images.sort((a: any, b: any) => a?.name > b?.name ? 1 : -1);
  // ---

  let strapiI18NContent: any | undefined;

  if (_userLocale.includes('it') || _userLocale.includes('IT')) {
    strapiI18NContent = useSiteProjectDetailI18N_IT();
    strapiI18NContent = strapiI18NContent.map((el: any) => el?.node?.localizations?.data[0]?.attributes);
    strapiI18NContent = strapiI18NContent.filter((el: any) => currentPath && (el?.title.toLowerCase() as string).includes(currentPath))[0];
  } else {
    strapiI18NContent = useSiteProjectDetailI18N_EN();
    strapiI18NContent = strapiI18NContent.map((el: any) => el?.node).filter((el: any) => currentPath && (el?.title.toLowerCase() as string).includes(currentPath))[0];
  }
  
  const currentTheme: 'light' | 'dark' = getTheme();
    
  // Hook w/ text blocks animations and scrollTriggers
  useEffect(() => {

    const textParallax: gsap.core.Tween = gsap.to('.textParallax', {
      y: '6%',
      ease: 'power0.in',
      duration: 0.1,
    })

    ScrollTrigger.create({
      trigger: '#text1',
      start: 'top bottom',
      end: 'bottom 75%',
      animation: textParallax,
      toggleActions: 'play none none reverse',
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#text2',
      start: 'top bottom',
      end: 'center 75%',
      animation: textParallax,
      toggleActions: 'play none none reverse',
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#text3',
      start: 'top bottom',
      end: 'bottom 75%',
      animation: textParallax,
      toggleActions: 'play none none reverse',
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#text4',
      start: 'top bottom',
      end: 'center 75%',
      animation: textParallax,
      toggleActions: 'play none none reverse',
      scrub: 1
    })

    // On refresh/reload go to top of page to clean errors/bugs that might appear from parallax animation
    window.addEventListener("beforeunload", () => {window.scrollTo(0, 0)});
    return () => window.removeEventListener("beforeunload", () => {window.scrollTo(0, 0)})

    }, [])
    // ---

  return (
    <DefaultAppView currentUrl={`/project-detail/${currentPath}`} userLocale={_userLocale}>
            
      {/* --- START - Intro Section --- */}
      <ProjectDetailIntro 
        projectName={strapiI18NContent?.title}
        projectYear={strapiI18NContent?.year}
        projectDescription={strapiI18NContent?.description}
        projectIntroImage={images && images[0]} 
        currentTheme={currentTheme} 
        userLocale={_userLocale}
      />
      {/* --- */}

      {/* --- START - 1st Banner w/ parallax --- */}
      <div id='reverse-bg-zone-trigger' className='relative flex flex-col items-center w-full h-[32vh] lg:h-screen bg-white'>
        <BannerParallax imageObj={images && images[1]} projectName={currentPath} currentTheme={currentTheme}/>
      </div>
      {/* --- */}
            
      {/* --- START - Project 1st description --- */}
      <div id='text1' className='flex flex-col w-full px-6 bg-black h-min lg:px-24 xl:px-36 lg:pb-48 text-cardinal-white'>
        <span className='uppercase lg:text-lg lg:pb-20 xl:pb-32'>
          { (_userLocale.includes('it') || _userLocale.includes('IT')) ? 'Il progetto' : 'The project'}
        </span>
        <div className='w-full lg:text-5xl xl:text-6xl textParallax'>
          { strapiI18NContent?.projectDescription_1 }
        </div>
      </div>
      {/* --- */}

      {/* --- START - Project logos --- */}
      <ProjectDetailLogos logoA={images && images[3]} logoB={images && images[4]}/>
      {/* --- */}

      {/* --- START - How it works section --- */}
      <div className='relative z-10 flex w-full min-h-screen px-6 bg-black pb-28 lg:py-20 3xl:pb-0 text-cardinal-white lg:px-24 xl:px-36'>
        <div id='text2' className='flex flex-col lg:flex-row textParallax space-y-11 lg:space-y-0'>
          <div className='h-full lg:w-1/5'>
            <span className='text-2xl uppercase lg:text-lg'>
              { (_userLocale.includes('it') || _userLocale.includes('IT')) ? 'come funziona' : 'how it works'}
            </span>
          </div>
          <div className='flex flex-col w-full h-full px-5 font-light lg:w-2/5 lg:text-2xl 3xl:text-3xl'>
            { strapiI18NContent && 
              strapiI18NContent?.projectFunctionality_1.split('-').map((paragraph: string, index: number) => {
                return (
                  <span key={index}>{paragraph}<br/></span>
                )
              })
            }
          </div>
          <div className='flex flex-col w-full h-full px-5 font-light lg:w-2/5 lg:text-2xl 3xl:text-3xl'>
            { strapiI18NContent && 
              strapiI18NContent?.projectFunctionality_2.split('-').map((paragraph: string, index: number) => {
                return (
                  <span key={index}>{paragraph}<br/></span>
                )
              })
            }
          </div> 
        </div>
      </div>
      {/* --- */}

      {/* --- START - 2nd Banner w/ parallax --- */}
      <div className='relative flex flex-col items-center w-full h-[32vh] lg:h-screen bg-white'>
        <BannerParallax imageObj={images && images[2]} projectName={currentPath} currentTheme={currentTheme}/>
      </div>
      {/* --- */}

      {/* --- START - Project 2nd description --- */}
      <div className='relative flex flex-col justify-between px-6 pb-20 bg-black lg:min-h-screen lg:px-24 xl:px-36 xl:py-36 3xl:pb-60 text-cardinal-white'>
        <span id='text3' className='pb-4 text-2xl font-medium lg:text-5xl xl:text-6xl lg:pb-0 textParallax'>{strapiI18NContent && strapiI18NContent?.projectDescription_2}</span>
          <div id='text4' className='flex flex-row-reverse items-start justify-start w-full pt-24 space-x-16 font-light textParallax'>
            <span className='w-full px-6 text-base lg:w-1/3 lg:text-2xl 3xl:text-3xl'>{strapiI18NContent && strapiI18NContent?.projectSmallDesc_1}</span>
            <span className='hidden w-1/3 pl-6 lg:text-2xl 3xl:text-3xl lg:flex'>{strapiI18NContent && strapiI18NContent?.projectSmallDesc_2}</span>
          </div>
      </div>
      {/* --- */}
        
      {/* --- START - 3rd Banner w/ parallax --- */}
      <div className='relative flex flex-col items-center w-full h-[32vh] lg:h-screen bg-white'>
        <BannerParallax imageObj={images && images[0]} projectName={currentPath} currentTheme={currentTheme}/>
      </div>
      {/* --- */}

      {/* --- START - Change project detail --- */}
      <ProjectDetailChange nextProjectImage={currentPath?.includes('freename') ? (images && images[3]) : (images && images[4])} projects={project} currentPath={currentPath}/>
      {/* --- */}

    </DefaultAppView>
  )
}

export default ProjectsDetail;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    allStrapiProject {
      edges {
        node {
          SEO {
            metaTitle
            metaDescription
            keywords
            preventIndexing
            pathname
          }
        }
      }
    }
  }
`

export const Head = () => {
  const projectsSeos: any = seoData && useStaticQuery(seoData).allStrapiProject.edges;
  const projectTitle: string | undefined = isRunningOnWeb() ? window.location.pathname.split('/').slice(1, 3).pop() : undefined;
  const seo: SEOData = projectsSeos.filter((el: any) => el?.node && el.node?.SEO.pathname.includes(projectTitle))[0]?.node.SEO;
  // console.log(projectsSeos, projectTitle, seo)
  
  return (
    <SEO 
      title={seo?.metaTitle} 
      description={seo?.metaDescription} 
      keywords={seo?.keywords} 
      preventIndexing={seo?.preventIndexing} 
      pathname={seo?.pathname} />   
  )
}