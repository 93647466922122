import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

// --- BANNER PARALLAX -> Image banner with parallax animation on scroll
const BannerParallax = (props: {
  imageObj: any,                      // ->  path of image datas
  projectName: string | undefined,    // ->  name of the project where the banner will be displayed
  currentTheme: 'light' | 'dark',     // ->  theme type
}) => {

  // --- Getting image 
  const image: IGatsbyImageData = props?.imageObj && getImage(props.imageObj.localFile);
  // ---

  // --- Setting scrollTrigger name, id and triggers
  const trigger: string = '#' + props?.imageObj?.caption;
  const classAnim: string = '.bannerParallax';
  const endTrigger: string = trigger.includes('-0') ? 'bottom center' : 'bottom top';
  // ---

  // --- Hook w/ parallx animation and scrollTrigger
  useEffect(() => {
       
    // gsap animation
    const parallaxAnim: gsap.core.Tween = gsap.to(classAnim, {
      y: '-10%',
      ease: 'power0.in',
      duration: 0.1,
    })

    // scrollTrigger
    ScrollTrigger.create({
      trigger: trigger,
      // markers: true,
      // id: 'prova',
      start: 'top bottom',
      end: endTrigger,
      animation: parallaxAnim,
      toggleActions: 'play none reverse reset',
      scrub: 1
    })

  },[])
  // ---

  return (
      <>
        {/* --- Banner HTML w/ checks for image caption and project name --- */}
        <div className={`absolute z-10 w-full top-0 
          ${ trigger.includes('-1') ? (props?.currentTheme && props.currentTheme === 'light' ? 'bg-cardinal-white h-[5%]' : 'bg-black') : 
          trigger.includes('-2') ? 'bg-black h-[5%]' : 'h-1/4 bg-black'}` }/>
            
          <div id={props?.imageObj?.caption} className='flex w-full h-full bg-black'>
            <GatsbyImage image={image} alt={props?.imageObj?.caption} className={`z-0 w-full h-full bannerParallax ${trigger.includes('-0') && '-bottom-[20%]'}`}/>
          </div>
            
          <div className={`absolute w-full bg-black ${trigger.includes('-1') ? `bottom-[0%] lg:h-2/5 xl:h-1/6 ${trigger.includes('cloudname') ? '' : ''}` : 
            trigger.includes('-2') ? 'bottom-0 lg:h-2/5 xl:h-1/6' : 'lg:h-1/5 xl:hidden'}`}/>
      </>
  )
}

export default BannerParallax;